let moment = require('moment');
window.moment = moment;

let Handlebars = require('handlebars');
window.Handlebars = Handlebars;

let Fuse = require('fuse.js').default;
window.Fuse = Fuse;

_.assign(Fuse.config, {
  isCaseSensitive: false,
  shouldSort: true,
  minMatchCharLength: 2,
  findAllMatches: true,
  threshold: 0.4,
  ignoreLocation: true,
});

Handlebars.registerHelper({
  eq: (a, b) => a === b,
  ne: (a, b) => a !== b,
  lt: (a, b) => a < b,
  gt: (a, b) => a > b,
  lte: (a, b) => a <= b,
  gte: (a, b) => a >= b,
  not: (a) => !a,
  neg: (a) => -a,
  or() {
    return Array.prototype.slice.call(arguments, 0, -1).some(Boolean);
  },
  and() {
    return Array.prototype.slice.call(arguments, 0, -1).every(Boolean);
  },
  list() {
    return Array.prototype.slice.call(arguments, 0, -1);
  },
  call() {
    return _.isFunction(arguments[0]) ? arguments[0].call(...Array.prototype.slice.call(arguments, 0, -1)) : undefined;
  },
  bind: (a,b) => a.bind(b),
  regexp() {
    return new RegExp(...Array.prototype.slice.call(arguments, 0, -1));
  },
  math: function(lvalue, operator, rvalue) {
    lvalue = parseFloat(lvalue);
    rvalue = parseFloat(rvalue);
    return {
      '+': lvalue + rvalue,
      '-': lvalue - rvalue,
      '*': lvalue * rvalue,
      '/': lvalue / rvalue,
      '%': lvalue % rvalue,
    }[operator];
  },
  stringify() {
    let args = Array.prototype.slice.call(arguments, 0, arguments.length-1);
    return JSON.stringify.call(JSON, ...args);
  },
  parse: x => JSON.parse(x),
  helperMissing() {
    let options = arguments[arguments.length-1];
    let args = Array.prototype.slice.call(arguments, 0, arguments.length-1);
    for (let scope of [utils, _]) {
      if (_.isFunction(scope[options.name])) {
        try {
          return scope[options.name].call(scope, ...args);
        } catch (ex) {
          let msg = `${options.name}(${args}) failed with ${ex.message}`;
          console.warn(msg);
          return msg;
        }
      }
    }
    if (args[0] && args[0].__proto__[options.name]) {
      try {
        return args[0].__proto__[options.name].call(...args);
      } catch (ex) {
        let msg = `${options.name}(${args}) failed with ${ex.message}`;
        console.warn(msg);
        return msg;
      }
    }
    return null;
  },
});

Handlebars.registerHelper('switch', function(options) {
  var data = this;
  data.__check_conditions = true;
  return options.fn(this);
});

Handlebars.registerHelper('case', function(conditional, options) {
  if(conditional && this.__check_conditions) {
    this.__check_conditions = false;
    return options.fn(this);
  } else {
    return options.inverse(this);
  }
});
